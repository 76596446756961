<template>
  <Head>
    <Style
      type="text/css"
      :children="`:root { ${bgColor} ${bgAltColor} ${textColor} ${navColor} ${buttonColor} ${textAltColor}}`"
    ></Style>
  </Head>

  <NuxtLoadingIndicator :color="theme.bg_color" />
</template>

<script setup lang="ts">
const { store } = useDomainState()

const custom = !!store.value?.use_custom_colors

const theme = {
  bg_color: custom ? store.value!.bg_color : '#f2f2f2',
  bg_alt_color: custom ? store.value!.bg_alt_color : '#ffffff',
  text_color: custom ? store.value!.text_color : '#130b0b', // used with bg, bg_alt colors
  nav_color: custom ? store.value!.nav_color : '#d52826',
  button_color: custom ? store.value!.button_color : '#4e4e4e',
  text_alt_color: custom ? store.value!.text_alt_color : '#ffffff' // used with nav, button color
}

const bgColor = useGetRGBColor(theme.bg_color, 'bg-color')
const bgAltColor = useGetRGBColor(theme.bg_alt_color, 'bg-alt-color')
const textColor = useGetRGBColor(theme.text_color, 'text-color')
const navColor = useGetRGBColor(theme.nav_color, 'nav-color')
const buttonColor = useGetRGBColor(theme.button_color, 'button-color')
const textAltColor = useGetRGBColor(theme.text_alt_color, 'text-alt-color')
</script>
