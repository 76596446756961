<template>
  <RikazAction
    v-if="viewIcon"
    route="/my-account/notifications"
    :has-badge="true"
    :transition-key="unReadCount"
    position="top-0 ltr:left-1 rtl:right-1"
    :label="t('aria_page_label', { pae: t('notifications') })"
  >
    <div class="navbar-action">
      <div class="navbar-link">
        <v-icon icon="mdi mdi-bell-outline" />
      </div>
    </div>

    <template #badge>
      <div v-if="unReadCount" class="action-badge platform">
        <div>{{ unReadCount }}</div>
      </div>
    </template>
  </RikazAction>
</template>

<script setup lang="ts">
import { onMessage } from 'firebase/messaging'
import { useToast } from 'vue-toastification'
import toestNotification from './toestNotification.vue'
import { useNotificationStore } from '~~/store/notifications'
const toast = useToast()
const cart = useCartStore()
const { setFcmToken } = useSetFcmToken()
const { fetchUnRead, setUnRead } = useNotificationStore()
const { t } = useI18n()
const { isAuthLoggedIn } = useMenuModel()
withDefaults(
  defineProps<{
    viewIcon?: boolean
  }>(),
  {
    viewIcon: true
  }
)
const userInteracted = ref<boolean>(false)
const unReadCount = ref<number>(0)
const isAuth = isAuthLoggedIn()

if (isAuth) {
  await fetchUnReadLocal()
} else {
  unReadCount.value = 0
}
onMounted(() => {
  nextTick(async () => {
    /*  if (isAuth) {
      await initFirebaseSettings()
    } */

    setUnRead(0)

    await cart.serverLoad()
  })
})
async function fetchUnReadLocal() {
  unReadCount.value = await fetchUnRead()
}
async function initFirebaseSettings() {
  initSwMessaging()
  await initFirebase()
  window.addEventListener(
    'click',
    () => {
      userInteracted.value = true
    },
    { once: true }
  )
  window.addEventListener(
    'touchstart',
    () => {
      userInteracted.value = true
    },
    { once: true }
  )
}
function initSwMessaging() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (!registration) {
          navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            scope: '/firebase-cloud-messaging-push-scope'
          })
        }
      })
    })
  }
}
const isRtl = useIsRtl()
async function initFirebase() {
  try {
    const messaging = await setFcmToken()
    if (messaging) {
      messagingHandler(messaging)
    }
  } catch (error) {}
}
function runAudioForTypeNotification(typeSound?: string) {
  if (userInteracted) {
    let audio
    if (typeSound === 'offer') {
      audio = new Audio('/sound/offer.wav')
    } else if (typeSound === 'bill') {
      audio = new Audio('/sound/bill.wav')
    }

    if (audio != null) {
      audio.play()
    }
  }
}
function messagingHandler(messaging) {
  onMessage(messaging, (payload) => {
    runAudioForTypeNotification(payload.data?.sound)
    fetchUnReadLocal()
    if (payload.data) {
      const toastId = toast({
        component: toestNotification,
        position: 'top-right',
        timeout: 2000,
        props: {
          title: payload.data.title,
          type: payload.data.type,
          id: payload.data.id,
          discription: payload.data.body
        },
        closeOnClick: true,
        listeners: {
          onTap: () => {
            toast.dismiss(toastId)
            openNotification(payload.data)
          }
        },

        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: isRtl
      })
    }
  })
}
const router = useRouter()
const localePath = useLocalePath()
function openNotification(data: any) {
  const type = data?.type
  const id = data?.id
  const relatedId = data?.related_id
  const relatedType = data?.english_related_type
  if (useTypeNotificationIsForClient(type) && id) {
    router.push(localePath(`/my-account/orders/${id}`))
  }
  if (useTypeNotificationIsForMoneyMovement(type)) {
    router.push(localePath('/my-account/money-movements'))
  }
  if (relatedId && relatedType) {
    if (relatedType === 'Material') {
      return router.push(
        localePath({
          name: 'materials-id',
          params: { id: relatedId }
        })
      )
    }
    if (relatedType === 'Material') {
      return router.push(
        localePath({
          name: 'stores-id',
          params: { id: relatedId }
        })
      )
    }
  }
}
</script>
